import React from 'react';
import Chladni from '../Chladni';

export default function CTA({ children }) {
  const animationContainerRef = React.useRef(null);

  return (
    <div className="container text-indigo bg-yellow mx-auto py-32 relative">
      <div className="absolute inset-0" ref={animationContainerRef}>
        <Chladni />
      </div>

      <div className="z-10 relative">{children}</div>
    </div>
  );
}
