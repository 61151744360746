import React from 'react';
import ChladniPattern from './ChladniPattern';

export default function Chladni() {
  const canvasRef = React.useRef(null);
  const chladniRef = React.useRef(null);

  React.useLayoutEffect(() => {
    const canvas = canvasRef.current;

    chladniRef.current = new ChladniPattern(canvas);
  }, []);

  return <canvas ref={canvasRef} className="w-full h-full" />;
}
