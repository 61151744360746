/**
 * https://stackoverflow.com/a/52827031/778272
 * @returns {Boolean} true if system is big endian */
const isBigEndian = (() => {
  const array = new Uint8Array(4);
  const view = new Uint32Array(array.buffer);
  return !((view[0] = 1) & array[0]);
})();
// console.info('Endianness: ' + (isBigEndian ? 'big' : 'little'));

const rgbToVal = isBigEndian
  ? (r, g, b) => ((r << 24) | (g << 16) | (b << 8) | 0xff) >>> 0
  : (r, g, b) => ((0xff << 24) | (b << 16) | (g << 8) | r) >>> 0;

function hexColorToColor(hexColor) {
  const cssColor = hexColor
    ? parseInt(hexColor.replace(/[^a-fA-F0-9]/g, ''), 16)
    : null;

  return rgbToVal(
    (cssColor >>> 16) & 0xff,
    (cssColor >>> 8) & 0xff,
    cssColor & 0xff
  );
}

class Debouncer {
  constructor() {
    this.timer = null;
  }
  set(task, delay) {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      this.timer = null;
      task();
    }, delay);
  }
}

export { isBigEndian, rgbToVal, hexColorToColor, Debouncer };
